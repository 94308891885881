import React, { useState, useEffect } from 'react';
import Logo from '../../assets/images/Logo.png';

// Import all images from the HomeHeroImages directory
import SCHero1 from '../../assets/images/HomeHeroImages/SCHero1.jpeg';
import SCHero2 from '../../assets/images/HomeHeroImages/SCHero2.jpeg';
import SCHero3 from '../../assets/images/HomeHeroImages/SCHero3.jpeg';
import { Link } from 'react-router-dom';

const images = [SCHero1, SCHero2, SCHero3];

function HomeHero() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000); // Increased to 8 seconds to better match the animation

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-screen w-full overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 w-full h-full transition-opacity duration-2000
            ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'}`}
        >
          <div
            className="absolute inset-0 w-full h-full bg-cover bg-center animate-kenburns"
            style={{
              backgroundImage: `url(${image})`,
              animationDelay: `${index * -8}s`, // Negative delay to ensure continuous animation
            }}
          />
        </div>
      ))}
      
      <div className="relative z-10 flex items-center justify-center h-full">
        <div className="absolute inset-0 bg-gradient-to-b from-primary/90 to-primary/70"></div>
        <div className="hero-content text-neutral-content text-center relative z-20">
          <div className="">
<img src={Logo} alt="Logo" className="w-40 h-40 mx-auto mb-5" />
            <h1 className="md:text-5xl text-xl font-secondary text-white tracking-widest-plus">THE SKIN</h1>
            <h2 className="font-primary md:text-5xl text-xl text-white tracking-super-wide">CHALET</h2>
           
            <Link to="/booking">
            <button className="button-primary mt-16">Book an Appointment</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeHero;

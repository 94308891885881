import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const BookingSettings = () => {
  const [businessHours, setBusinessHours] = useState({
    monday: { start: '09:00', end: '17:00', isOpen: true },
    tuesday: { start: '09:00', end: '17:00', isOpen: true },
    wednesday: { start: '09:00', end: '17:00', isOpen: true },
    thursday: { start: '09:00', end: '17:00', isOpen: true },
    friday: { start: '09:00', end: '17:00', isOpen: true },
    saturday: { start: '09:00', end: '17:00', isOpen: false },
    sunday: { start: '09:00', end: '17:00', isOpen: false },
  });
  const [holidayDates, setHolidayDates] = useState([]);
  const [newHolidayDate, setNewHolidayDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState('');

  const orderedDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ];

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'booking'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setBusinessHours(data.businessHours || businessHours);
          setHolidayDates(data.holidayDates || []);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleHoursChange = (day, field, value) => {
    setBusinessHours(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        [field]: value
      }
    }));
  };

  const handleDayToggle = (day) => {
    setBusinessHours(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        isOpen: !prev[day].isOpen
      }
    }));
  };

  const addHolidayDate = () => {
    if (newHolidayDate && !holidayDates.includes(newHolidayDate)) {
      setHolidayDates(prev => [...prev, newHolidayDate].sort());
      setNewHolidayDate('');
    }
  };

  const removeHolidayDate = (dateToRemove) => {
    setHolidayDates(prev => prev.filter(date => date !== dateToRemove));
  };

  const saveSettings = async () => {
    try {
      setSaveStatus('Saving...');
      await setDoc(doc(db, 'settings', 'booking'), {
        businessHours,
        holidayDates,
        lastUpdated: new Date().toISOString()
      });
      setSaveStatus('Settings saved successfully!');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error saving settings:', error);
      setSaveStatus('Error saving settings');
    }
  };

  const initializeDefaultSettings = async () => {
    try {
      setSaveStatus('Initializing default settings...');
      await setDoc(doc(db, 'settings', 'booking'), {
        businessHours,
        holidayDates: [],
        lastUpdated: new Date().toISOString()
      });
      setSaveStatus('Default settings initialized successfully!');
      setTimeout(() => setSaveStatus(''), 3000);
    } catch (error) {
      console.error('Error initializing settings:', error);
      setSaveStatus('Error initializing settings');
    }
  };

  if (loading) {
    return <div>Loading settings...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-gray-900">Booking Settings</h2>
        <button
          onClick={initializeDefaultSettings}
          className="px-4 py-2 bg-primary text-white rounded hover:bg-primary/90 transition-colors"
        >
          Initialize Default Settings
        </button>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Business Hours</h3>
        <div className="space-y-4">
          {orderedDays.map(day => (
            <div key={day} className="flex items-center space-x-4">
              <div className="w-32 capitalize">{day}</div>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={businessHours[day].isOpen}
                  onChange={() => handleDayToggle(day)}
                  className="form-checkbox h-4 w-4 text-primary"
                />
                <span className="ml-2">Open</span>
              </label>
              <input
                type="time"
                value={businessHours[day].start}
                onChange={(e) => handleHoursChange(day, 'start', e.target.value)}
                disabled={!businessHours[day].isOpen}
                className="form-input"
              />
              <span>to</span>
              <input
                type="time"
                value={businessHours[day].end}
                onChange={(e) => handleHoursChange(day, 'end', e.target.value)}
                disabled={!businessHours[day].isOpen}
                className="form-input"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Holiday Dates</h3>
        <div className="flex space-x-4 mb-4">
          <input
            type="date"
            value={newHolidayDate}
            onChange={(e) => setNewHolidayDate(e.target.value)}
            className="form-input"
          />
          <button
            onClick={addHolidayDate}
            className="button-secondary"
          >
            Add Holiday
          </button>
        </div>
        <div className="space-y-2">
          {holidayDates.map(date => (
            <div key={date} className="flex items-center justify-between bg-gray-50 p-2 rounded">
              <span>{new Date(date).toLocaleDateString()}</span>
              <button
                onClick={() => removeHolidayDate(date)}
                className="text-red-600 hover:text-red-800"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          onClick={saveSettings}
          className="button-secondary"
        >
          Save Settings
        </button>
      </div>
      {saveStatus && (
        <div className="text-center text-sm font-medium text-gray-700">
          {saveStatus}
        </div>
      )}
    </div>
  );
};

export default BookingSettings;

import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import SectionSideBySide from '../components/SectionTemplate/SectionSideBySide';
import contactHero from '../assets/images/contactHero.jpeg';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [businessHours, setBusinessHours] = useState(null);
  const [loading, setLoading] = useState(true);

  const dayOrder = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ];

  useEffect(() => {
    const fetchBusinessHours = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'booking'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          console.log('Fetched business hours:', data.businessHours);
          setBusinessHours(data.businessHours);
        }
      } catch (error) {
        console.error('Error fetching business hours:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessHours();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submission logic would go here
    console.log('Form submitted:', formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const formatDayName = (day) => {
    return day.charAt(0).toUpperCase() + day.slice(1);
  };

  const formatTime = (time) => {
    if (!time) return '';
    
    try {
      const [hours, minutes] = time.split(':');
      const hour = parseInt(hours);
      const ampm = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      return `${hour12}:${minutes} ${ampm}`;
    } catch (error) {
      return time;
    }
  };

  const renderBusinessHours = () => {
    if (loading) {
      return <p className="font-body">Loading hours...</p>;
    }

    if (!businessHours) {
      return (
        <div className="font-body leading-relaxed space-y-2">
          <p>Monday - Friday: 9:00 AM - 7:00 PM</p>
          <p>Saturday: 10:00 AM - 6:00 PM</p>
          <p>Sunday: Closed</p>
        </div>
      );
    }

    return (
      <div className="font-body leading-relaxed space-y-2">
        {dayOrder.map(day => {
          const dayHours = businessHours[day];
          return (
            <p key={day} className="capitalize">
              {formatDayName(day)}: {
                dayHours?.isOpen 
                  ? `${formatTime(dayHours.start)} - ${formatTime(dayHours.end)}`
                  : 'Closed'
              }
            </p>
          );
        })}
      </div>
    );
  };

  return (
    <div className="pt-24">
      <SectionSideBySide
        img={contactHero} 
        heading="Get in Touch"
        text="We'd love to hear from you. Whether you have questions about our services, need skincare advice, or want to book an appointment, our team is here to help."
      />

      <section className="w-full py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row gap-16">
            {/* Contact Form */}
            <div className="w-full md:w-1/2">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block font-body text-sm tracking-widest mb-2">
                    NAME
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border-2 border-primary/20 focus:border-primary focus:outline-none font-body"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block font-body text-sm tracking-widest mb-2">
                    EMAIL
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border-2 border-primary/20 focus:border-primary focus:outline-none font-body"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block font-body text-sm tracking-widest mb-2">
                    MESSAGE
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="6"
                    className="w-full px-4 py-2 border-2 border-primary/20 focus:border-primary focus:outline-none font-body resize-none"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full bg-primary text-white font-body tracking-widest py-3 hover:bg-primary/90 transition-colors duration-200"
                >
                  SEND MESSAGE
                </button>
              </form>
            </div>

            {/* Contact Information */}
            <div className="w-full md:w-1/2 space-y-8">
              <div>
                <h3 className="font-secondary text-2xl mb-4">Contact</h3>
                <p className="font-body leading-relaxed">
                  Email: info@skinchalet.com
                </p>
              </div>
              <div>
                <h3 className="font-secondary text-2xl mb-4">Hours</h3>
                {renderBusinessHours()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;

import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { db } from '../../config/firebase';
import { collection, getDocs, query, orderBy, where, doc, getDoc } from 'firebase/firestore';
import ListView from './BookingViews/ListView';
import CalendarView from './BookingViews/CalendarView';
import WeeklyView from './BookingViews/WeeklyView';
import BookingDetails from './BookingViews/BookingDetails';

const ViewBookings = () => {
  const [viewMode, setViewMode] = useState('list');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [currentWeek, setCurrentWeek] = useState(dayjs().startOf('week'));
  
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setLoading(true);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const bookingsQuery = query(
          collection(db, 'bookings'),
          where('dateTime', '>=', today),
          orderBy('dateTime', 'asc')
        );
        const snapshot = await getDocs(bookingsQuery);
        
        // Fetch all bookings first
        const bookingsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime?.toDate()
        }));

        // Create a map of unique user IDs
        const userIds = [...new Set(bookingsData.map(booking => booking.userId))];

        // Fetch user data for all users in parallel
        const userDataPromises = userIds.map(async userId => {
          const userDoc = await getDoc(doc(db, 'users', userId));
          return {
            userId,
            adminNotes: userDoc.data()?.adminNotes || ''
          };
        });

        const userData = await Promise.all(userDataPromises);
        const userNotesMap = Object.fromEntries(
          userData.map(data => [data.userId, data.adminNotes])
        );

        // Combine booking data with user notes
        const bookingsWithNotes = bookingsData.map(booking => ({
          ...booking,
          adminNotes: userNotesMap[booking.userId] || ''
        }));

        setBookings(bookingsWithNotes);
      } catch (err) {
        console.error('Error fetching bookings:', err);
        setError('Failed to load bookings');
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
  };

  const handleCloseBookingDetails = () => {
    setSelectedBooking(null);
  };

  const handleBookingUpdate = (updatedBooking) => {
    // Update the booking in the local state
    setBookings(prevBookings => 
      prevBookings.map(booking => 
        booking.id === updatedBooking.id ? updatedBooking : booking
      )
    );
    // Update the selected booking if it's currently being viewed
    if (selectedBooking?.id === updatedBooking.id) {
      setSelectedBooking(updatedBooking);
    }
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(prev => prev.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentMonth(prev => prev.add(1, 'month'));
  };

  const handlePreviousWeek = () => {
    setCurrentWeek(prev => prev.subtract(1, 'week'));
  };

  const handleNextWeek = () => {
    setCurrentWeek(prev => prev.add(1, 'week'));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-xl text-gray-600">Loading bookings...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-xl text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">View Bookings</h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setViewMode('list')}
            className={`px-4 py-2 rounded-md ${
              viewMode === 'list'
                ? 'bg-primary text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            List View
          </button>
          <button
            onClick={() => setViewMode('calendar')}
            className={`px-4 py-2 rounded-md ${
              viewMode === 'calendar'
                ? 'bg-primary text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Calendar View
          </button>
          <button
            onClick={() => setViewMode('weekly')}
            className={`px-4 py-2 rounded-md ${
              viewMode === 'weekly'
                ? 'bg-primary text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Weekly View
          </button>
        </div>
      </div>

      {viewMode === 'list' && (
        <ListView 
          bookings={bookings} 
          onBookingClick={handleBookingClick} 
        />
      )}
      
      {viewMode === 'calendar' && (
        <CalendarView 
          currentMonth={currentMonth}
          onPreviousMonth={handlePreviousMonth}
          onNextMonth={handleNextMonth}
          onDateClick={handleDateClick}
          bookings={bookings}
        />
      )}
      
      {viewMode === 'weekly' && (
        <WeeklyView 
          currentWeek={currentWeek}
          onPreviousWeek={handlePreviousWeek}
          onNextWeek={handleNextWeek}
          onDateClick={handleDateClick}
          onBookingClick={handleBookingClick}
          bookings={bookings}
        />
      )}

      {selectedBooking && (
        <BookingDetails 
          booking={selectedBooking}
          onClose={handleCloseBookingDetails}
          onBookingUpdate={handleBookingUpdate}
        />
      )}
    </div>
  );
};

export default ViewBookings;

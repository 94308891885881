import React from 'react';

function CategoryFilter({ categories, selectedCategory, onCategorySelect }) {
  return (
    <div className="flex space-x-3 overflow-x-auto pb-4 mb-20 scrollbar-hide">
      <button
        onClick={() => onCategorySelect(null)}
        className={`px-5 py-1.5 rounded-full whitespace-nowrap text-xs font-medium tracking-[0.15em] ${
          selectedCategory === null
            ? 'bg-black text-white'
            : 'bg-gray-50 text-gray-900 hover:bg-gray-100'
        }`}
      >
        ALL SERVICES
      </button>
      {categories.map(category => (
        <button
          key={category.id}
          onClick={() => onCategorySelect(category.name.toLowerCase())}
          className={`px-5 py-1.5 rounded-full whitespace-nowrap text-xs font-medium tracking-[0.15em] ${
            selectedCategory === category.name.toLowerCase()
              ? 'bg-black text-white'
              : 'bg-gray-50 text-gray-900 hover:bg-gray-100'
          }`}
        >
          {category.name.toUpperCase()}
        </button>
      ))}
    </div>
  );
}

export default CategoryFilter;

import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { collection, addDoc, getDocs, query } from 'firebase/firestore';

const ManageLocations = () => {
  const [locationName, setLocationName] = useState('');
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      const locationsQuery = query(collection(db, 'locations'));
      const querySnapshot = await getDocs(locationsQuery);
      const locationsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setLocations(locationsList);
    } catch (err) {
      setError('Failed to fetch locations');
      console.error('Error fetching locations:', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!locationName.trim()) return;

    setLoading(true);
    setError('');

    try {
      await addDoc(collection(db, 'locations'), {
        name: locationName.trim(),
        createdAt: new Date().toISOString()
      });
      
      setLocationName('');
      await fetchLocations();
    } catch (err) {
      setError('Failed to add location');
      console.error('Error adding location:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Manage Locations</h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="locationName" className="block text-sm font-medium text-gray-700">
              Location Name
            </label>
            <input
              type="text"
              id="locationName"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              placeholder="Enter location name"
            />
          </div>
          
          {error && (
            <div className="text-red-600 text-sm">
              {error}
            </div>
          )}
          
          <button
            type="submit"
            disabled={loading || !locationName.trim()}
            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ${
              (loading || !locationName.trim()) && 'opacity-50 cursor-not-allowed'
            }`}
          >
            {loading ? 'Adding...' : 'Add Location'}
          </button>
        </form>
      </div>

      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4">Existing Locations</h3>
        {locations.length === 0 ? (
          <p className="text-gray-500">No locations added yet.</p>
        ) : (
          <ul className="divide-y divide-gray-200">
            {locations.map((location) => (
              <li key={location.id} className="py-4">
                {location.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ManageLocations;

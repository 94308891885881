import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { collection, addDoc, getDocs } from 'firebase/firestore';

const AddService = ({ onSuccess }) => {
  const [serviceForm, setServiceForm] = useState({
    title: '',
    category: '',
    price: '',
    description: '',
    bookingLengthHours: '0',
    bookingLengthMinutes: '0'
  });
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'categories'));
      const categoriesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesList);
    } catch (error) {
      setMessage({ text: 'Error fetching categories: ' + error.message, type: 'error' });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServiceForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const priceNumber = parseFloat(serviceForm.price.replace('£', ''));
      const bookingLengthHours = parseInt(serviceForm.bookingLengthHours) || 0;
      const bookingLengthMinutes = parseInt(serviceForm.bookingLengthMinutes) || 0;
      
      await addDoc(collection(db, 'services'), {
        title: serviceForm.title,
        category: serviceForm.category,
        price: priceNumber,
        description: serviceForm.description,
        bookingLength: {
          hours: bookingLengthHours,
          minutes: bookingLengthMinutes
        },
        createdAt: new Date(),
        updatedAt: new Date()
      });

      setMessage({ text: 'Service added successfully!', type: 'success' });
      setServiceForm({
        title: '',
        category: '',
        price: '',
        description: '',
        bookingLengthHours: '0',
        bookingLengthMinutes: '0'
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      setMessage({ text: 'Error adding service: ' + error.message, type: 'error' });
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          Add New Service
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Create a new service offering
        </p>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {message.text && (
          <div className={`mb-4 p-4 rounded ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {message.text}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              required
              value={serviceForm.title}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700">
              Category
            </label>
            <select
              name="category"
              id="category"
              required
              value={serviceForm.category}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
              Price (£)
            </label>
            <input
              type="text"
              name="price"
              id="price"
              required
              value={serviceForm.price}
              onChange={handleInputChange}
              placeholder="99"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Booking Length
            </label>
            <div className="mt-1 grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="bookingLengthHours" className="block text-sm text-gray-500">
                  Hours
                </label>
                <input
                  type="number"
                  name="bookingLengthHours"
                  id="bookingLengthHours"
                  min="0"
                  required
                  value={serviceForm.bookingLengthHours}
                  onChange={handleInputChange}
                  className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="bookingLengthMinutes" className="block text-sm text-gray-500">
                  Minutes
                </label>
                <input
                  type="number"
                  name="bookingLengthMinutes"
                  id="bookingLengthMinutes"
                  min="0"
                  max="59"
                  required
                  value={serviceForm.bookingLengthMinutes}
                  onChange={handleInputChange}
                  className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              name="description"
              id="description"
              required
              rows={4}
              value={serviceForm.description}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div>
            <button
              type="submit"
              className="button-secondary w-full"
            >
              Add Service
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddService;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './style/tailwind.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import sample data initialization script in development
if (process.env.NODE_ENV === 'development') {
  import('./scripts/addSampleData');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();

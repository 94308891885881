import React from 'react';
import dayjs from 'dayjs';

const ListView = ({ bookings, onBookingClick }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date/Time
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Location
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Customer
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Services
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Total
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {bookings.map((booking) => (
            <tr key={booking.id} className="hover:bg-gray-50 cursor-pointer"
                onClick={() => onBookingClick(booking)}>
              <td className="px-6 py-4 whitespace-nowrap">
                {dayjs(booking.dateTime).format('MMM D, YYYY HH:mm')}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {booking.locationName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {booking.userEmail}
              </td>
              <td className="px-6 py-4">
                <div className="text-sm">
                  {booking.services.map((service, index) => (
                    <div key={index}>{service.title}</div>
                  ))}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                £{booking.totalPrice}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                  ${booking.status === 'confirmed' ? 'bg-green-100 text-green-800' : 
                    booking.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                    'bg-red-100 text-red-800'}`}>
                  {booking.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListView;

import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Link } from 'react-router-dom';

function ServiceCategories() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'categories'));
        const categoriesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <section className="w-full py-20 pt-32 bg-primary">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-secondary text-white text-center mb-16">SERVICES</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {categories.map((category) => (
            <div key={category.id} className="bg-white p-4">
              <div className="border-2 border-primary p-2 mb-6">
                <img
                  src={category.imageUrl}
                  alt={category.name}
                  className="w-full h-64 object-cover"
                />
              </div>
              <h3 className="text-2xl font-secondary text-center mb-4">
                {category.name.toUpperCase()}
              </h3>
              <p className="font-primary text-center mb-6">
                {category.shortDescription}
              </p>
              <div className="text-center">
                <Link
                  to={`/services/${category.name}`}
                  className="button-secondary"
                >
                  READ MORE...
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServiceCategories;

import React from 'react';

import ServiceCategories from '../components/ServiceCategories/ServiceCategories';

const Services = () => {
 

  return (
   <ServiceCategories />
  );
};

export default Services;

import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { db } from '../../../config/firebase';
import { doc, updateDoc } from 'firebase/firestore';

const BookingDetails = ({ booking, onClose, onBookingUpdate }) => {
  const [notes, setNotes] = useState('');
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState('');
  const [updatingStatus, setUpdatingStatus] = useState(false);

  useEffect(() => {
    // Initialize notes and status from the booking data
    if (booking?.adminNotes !== undefined) {
      setNotes(booking.adminNotes);
    }
    if (booking?.status) {
      setStatus(booking.status);
    }
  }, [booking?.adminNotes, booking?.status]);

  const handleSaveNotes = async () => {
    if (!booking?.userId) return;
    
    try {
      setSaving(true);
      const userRef = doc(db, 'users', booking.userId);
      await updateDoc(userRef, {
        adminNotes: notes
      });
      
      // Create updated booking object with new notes
      const updatedBooking = {
        ...booking,
        adminNotes: notes
      };
      
      // Update parent component state
      if (onBookingUpdate) {
        onBookingUpdate(updatedBooking);
      }
    } catch (error) {
      console.error('Error saving notes:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleStatusChange = async (newStatus) => {
    if (!booking?.id || status === newStatus) return;

    try {
      setUpdatingStatus(true);
      const bookingRef = doc(db, 'bookings', booking.id);
      const updatedAt = new Date();
      
      await updateDoc(bookingRef, {
        status: newStatus,
        updatedAt
      });

      // Create updated booking object
      const updatedBooking = {
        ...booking,
        status: newStatus,
        updatedAt
      };

      // Update local state
      setStatus(newStatus);
      
      // Update parent component state
      if (onBookingUpdate) {
        onBookingUpdate(updatedBooking);
      }

      // TODO: Trigger appropriate email based on status
      // This will be implemented later to send emails for:
      // - confirmed: Confirmation email to client
      // - rejected: Rejection notification to client
      // - cancelled: Cancellation notification to client

    } catch (error) {
      console.error('Error updating booking status:', error);
    } finally {
      setUpdatingStatus(false);
    }
  };

  if (!booking) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">
            Booking Details
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
            aria-label="Close"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="space-y-6">
          {/* Status Section */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">Current Status</p>
                <p className={`mt-1 text-sm font-semibold ${
                  status === 'confirmed' ? 'text-green-600' :
                  status === 'pending' ? 'text-yellow-600' :
                  status === 'rejected' ? 'text-red-600' :
                  'text-gray-600'
                }`}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <select
                  value={status}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  disabled={updatingStatus}
                  className="block w-40 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                >
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirm</option>
                  <option value="rejected">Reject</option>
                  <option value="cancelled">Cancel</option>
                </select>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm font-medium text-gray-500">Date</p>
              <p className="text-sm text-gray-900">{dayjs(booking.dateTime).format('MMMM D, YYYY')}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Time</p>
              <p className="text-sm text-gray-900">{dayjs(booking.dateTime).format('HH:mm')}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Location</p>
              <p className="text-sm text-gray-900">{booking.locationName}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Customer</p>
              <p className="text-sm text-gray-900">{booking.userEmail}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Total Duration</p>
              <p className="text-sm text-gray-900">
                {booking.totalDuration.hours}h {booking.totalDuration.minutes}m
              </p>
            </div>
          </div>

          <div>
            <p className="text-sm font-medium text-gray-500 mb-2">Services</p>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="space-y-2">
                {booking.services.map((service, index) => (
                  <div key={index} className="flex justify-between text-sm">
                    <span className="text-gray-900">{service.title}</span>
                    <span className="text-gray-600">£{service.price}</span>
                  </div>
                ))}
                <div className="pt-2 mt-2 border-t border-gray-200 flex justify-between text-sm font-medium">
                  <span className="text-gray-900">Total Price</span>
                  <span className="text-gray-900">£{booking.totalPrice}</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <p className="text-sm font-medium text-gray-500 mb-2">Client Notes</p>
            <div className="mt-1">
              <textarea
                rows={4}
                className="shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Add notes about this client..."
              />
            </div>
            <div className="mt-2 flex justify-end">
              <button
                onClick={handleSaveNotes}
                disabled={saving}
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ${
                  saving ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {saving ? 'Saving...' : 'Save Notes'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;

import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

function PaymentStep({ 
  totalPrice,
  onBack,
  onPaymentComplete,
  isSubmitting,
  error: parentError
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  
  const depositAmount = Math.floor(totalPrice * 50) / 100; // 50% of total, rounded to 2 decimal places

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }

    try {
      // TODO: Call your backend to create a payment intent
      // const { clientSecret } = await fetch('/api/create-payment-intent', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     amount: depositAmount,
      //     currency: 'gbp'
      //   }),
      // }).then(res => res.json());

      // const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
      //   clientSecret,
      //   {
      //     payment_method: {
      //       card: elements.getElement(CardElement),
      //     },
      //   }
      // );

      // if (stripeError) {
      //   setError(stripeError.message);
      // } else if (paymentIntent.status === 'succeeded') {
      //   onPaymentComplete(paymentIntent);
      // }

      // Temporary: Skip actual payment processing for now
      console.log('Payment would process £', depositAmount);
      onPaymentComplete({ id: 'mock_payment_id' });
    } catch (err) {
      setError('An error occurred while processing your payment. Please try again.');
    }
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <button
          onClick={onBack}
          className="text-gray-600 hover:text-gray-800 flex items-center"
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
          Back
        </button>
      </div>
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-semibold mb-6">Secure Your Booking</h2>
        <div className="mb-6">
          <p className="text-gray-600">
            A 50% deposit (£{depositAmount}) is required to secure your booking.
            The remaining balance will be due at your appointment.
          </p>
        </div>
        
        {(error || parentError) && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
            {error || parentError}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Card Details
            </label>
            <div className="border rounded-md p-3">
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>

          <button
            type="submit"
            disabled={!stripe || isSubmitting}
            className={`w-full bg-black text-white px-6 py-3 rounded-lg transition-colors ${
              (!stripe || isSubmitting) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-black/90'
            }`}
          >
            {isSubmitting ? 'Processing...' : `Pay £${depositAmount} Deposit`}
          </button>
        </form>
      </div>
    </div>
  );
}

export default PaymentStep;

import React from 'react';
import dayjs from 'dayjs';

const CalendarView = ({ currentMonth, onPreviousMonth, onNextMonth, onDateClick, bookings }) => {
  const firstDayOfMonth = currentMonth.startOf('month');
  const startOfCalendar = firstDayOfMonth.startOf('week');
  const days = Array.from({ length: 42 }, (_, i) => startOfCalendar.add(i, 'day'));
  const today = dayjs();

  const getBookingsForDate = (date) => {
    return bookings.filter(booking => 
      dayjs(booking.dateTime).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
    );
  };

  const getStatusCounts = (bookings) => {
    return bookings.reduce((acc, booking) => {
      acc[booking.status] = (acc[booking.status] || 0) + 1;
      return acc;
    }, {});
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'confirmed':
        return 'bg-green-100 text-green-800 border-green-200';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 border-yellow-200';
      case 'rejected':
        return 'bg-red-100 text-red-800 border-red-200';
      case 'cancelled':
        return 'bg-gray-100 text-gray-800 border-gray-200';
      default:
        return 'bg-gray-100 text-gray-800 border-gray-200';
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4">
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={onPreviousMonth}
          className="p-2 hover:bg-gray-100 rounded-full"
          aria-label="Previous month"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <div className="text-xl font-semibold text-center">
          {currentMonth.format('MMMM YYYY')}
        </div>
        <button
          onClick={onNextMonth}
          className="p-2 hover:bg-gray-100 rounded-full"
          aria-label="Next month"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>

      {/* Legend */}
      <div className="flex gap-4 mb-4 text-sm">
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-green-100 border border-green-200 mr-1"></div>
          <span>Confirmed</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-yellow-100 border border-yellow-200 mr-1"></div>
          <span>Pending</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-red-100 border border-red-200 mr-1"></div>
          <span>Rejected</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-gray-100 border border-gray-200 mr-1"></div>
          <span>Cancelled</span>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-2">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="text-center font-medium text-gray-500 p-2">
            {day}
          </div>
        ))}
        {days.map((date, index) => {
          const dayBookings = getBookingsForDate(date);
          const statusCounts = getStatusCounts(dayBookings);
          const isToday = date.format('YYYY-MM-DD') === today.format('YYYY-MM-DD');
          const isCurrentMonth = date.month() === currentMonth.month();
          const isPastDate = date.isBefore(today, 'day');

          return (
            <div
              key={index}
              onClick={() => !isPastDate && onDateClick(date.toDate())}
              className={`border p-2 min-h-[80px] ${
                isPastDate ? 'bg-gray-100 cursor-not-allowed' : 'cursor-pointer hover:bg-gray-50'
              } ${isToday ? 'bg-primary/10 border-primary' : ''} 
              ${!isCurrentMonth ? 'text-gray-400' : ''}`}
            >
              <div className="text-right">{date.format('D')}</div>
              {!isPastDate && dayBookings.length > 0 && (
                <div className="mt-1 space-y-1">
                  {Object.entries(statusCounts).map(([status, count]) => (
                    count > 0 && (
                      <div
                        key={status}
                        className={`inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full border ${getStatusColor(status)} mr-1`}
                      >
                        {count} {status}
                      </div>
                    )
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarView;

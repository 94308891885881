import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { auth, db } from '../../config/firebase';
import { useAuth } from '../../context/AuthContext';
import Logo from '../../assets/images/LogoAlt.png';

const NavigationBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesCollection = collection(db, 'categories');
        const categorySnapshot = await getDocs(categoriesCollection);
        const categoryList = categorySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(categoryList);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? 'hidden' : 'unset';
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const menuItems = [
    { label: 'BOOK NOW', path: '/booking' },
    { 
      label: 'SERVICES', 
      path: '/services',
      dropdown: categories.map(category => ({
        label: category.name.toUpperCase(),
        path: `/services/${encodeURIComponent(category.name)}`
      }))
    },
    { label: 'CONTACT', path: '/contact' },
  ];

  // Add admin link if user is admin
  if (user && isAdmin) {
    menuItems.push({ label: 'ADMIN', path: '/admin' });
  }

  const authItems = user
    ? [{ label: 'SIGN OUT', onClick: handleSignOut }]
    : [
        { label: 'SIGN IN', path: '/signin' },
        { label: 'SIGN UP', path: '/signup' },
      ];

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-24">
            {/* Logo */}
            <div className="flex-shrink-0">
              <Link to="/" onClick={closeMenu} className="block">
                <img src={Logo} alt="The Skin Chalet" className="h-14" />
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-12">
              {menuItems.map((item) => (
                <div key={item.label} className="relative group">
                  <Link
                    to={item.path}
                    className="font-body tracking-widest text-sm hover:text-primary transition-colors duration-200"
                  >
                    {item.label}
                  </Link>
                  {item.dropdown && (
                    <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 transform translate-y-1 group-hover:translate-y-0">
                      <div className="py-2">
                        {item.dropdown.map((dropdownItem) => (
                          <Link
                            key={dropdownItem.label}
                            to={dropdownItem.path}
                            className="block px-4 py-2 text-sm font-body tracking-widest hover:bg-gray-100 hover:text-primary transition-colors duration-200"
                          >
                            {dropdownItem.label}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {authItems.map((item) => (
                item.path ? (
                  <Link
                    key={item.label}
                    to={item.path}
                    className="font-body tracking-widest text-sm hover:text-primary transition-colors duration-200"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <button
                    key={item.label}
                    onClick={item.onClick}
                    className="font-body tracking-widest text-sm hover:text-primary transition-colors duration-200"
                  >
                    {item.label}
                  </button>
                )
              ))}
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-900 hover:text-primary focus:outline-none"
                aria-expanded={isOpen}
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile menu overlay with slide animation */}
      <div 
        className={`fixed inset-0 bg-black/40 backdrop-blur-md z-40 md:hidden transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={closeMenu}
      >
        <div 
          className={`fixed inset-0 bg-primary/90 backdrop-blur-md flex items-center justify-center transition-transform duration-500 ease-in-out ${
            isOpen ? 'translate-y-0' : '-translate-y-full'
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={`flex flex-col items-center justify-center space-y-8 transition-all duration-300 delay-100 ${
            isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'
          }`}>
            {menuItems.map((item) => (
              <div key={item.label} className="flex flex-col items-center">
                <Link
                  to={item.path}
                  className="text-white font-body tracking-widest text-xl hover:text-gray-200 transition-colors duration-200"
                  onClick={closeMenu}
                >
                  {item.label}
                </Link>
                {item.dropdown && (
                  <div className="mt-4 space-y-4">
                    {item.dropdown.map((dropdownItem) => (
                      <Link
                        key={dropdownItem.label}
                        to={dropdownItem.path}
                        className="block text-white font-body tracking-widest text-lg hover:text-gray-200 transition-colors duration-200"
                        onClick={closeMenu}
                      >
                        {dropdownItem.label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
            {authItems.map((item) => (
              item.path ? (
                <Link
                  key={item.label}
                  to={item.path}
                  className="text-white font-body tracking-widest text-xl hover:text-gray-200 transition-colors duration-200"
                  onClick={closeMenu}
                >
                  {item.label}
                </Link>
              ) : (
                <button
                  key={item.label}
                  onClick={() => {
                    item.onClick();
                    closeMenu();
                  }}
                  className="text-white font-body tracking-widest text-xl hover:text-gray-200 transition-colors duration-200"
                >
                  {item.label}
                </button>
              )
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationBar;

import React, { useState, useEffect } from 'react';
import { db, storage } from '../../config/firebase';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'categories'));
      const categoriesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesList);
    } catch (error) {
      setMessage({ text: 'Error fetching categories: ' + error.message, type: 'error' });
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let imageUrl = '';
      
      if (imageFile) {
        // Create a reference to the file in Firebase Storage
        const storageRef = ref(storage, `category-images/${Date.now()}-${imageFile.name}`);
        
        // Upload the file
        const snapshot = await uploadBytes(storageRef, imageFile);
        
        // Get the download URL
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      // Add the category to Firestore
      await addDoc(collection(db, 'categories'), {
        name: categoryName,
        shortDescription: shortDescription,
        description: description,
        imageUrl: imageUrl,
        createdAt: new Date()
      });

      setMessage({ text: 'Category added successfully!', type: 'success' });
      setCategoryName('');
      setShortDescription('');
      setDescription('');
      setImageFile(null);
      fetchCategories(); // Refresh the list
    } catch (error) {
      setMessage({ text: 'Error adding category: ' + error.message, type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          Manage Categories
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Add and view service categories
        </p>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {message.text && (
          <div className={`mb-4 p-4 rounded ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {message.text}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="categoryName" className="block text-sm font-medium text-gray-700">
              Category Name
            </label>
            <input
              type="text"
              id="categoryName"
              required
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="shortDescription" className="block text-sm font-medium text-gray-700">
              Short Description
            </label>
            <input
              type="text"
              id="shortDescription"
              required
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
              placeholder="Brief preview description"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Full Description
            </label>
            <textarea
              id="description"
              rows="3"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="image" className="block text-sm font-medium text-gray-700">
              Category Image
            </label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-1 block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-md file:border-0
                file:text-sm file:font-semibold
                file:bg-primary file:text-white
                hover:file:bg-primary/90"
            />
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`button-secondary w-full ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isLoading ? 'Adding Category...' : 'Add Category'}
            </button>
          </div>
        </form>

        <div className="mt-6">
          <h3 className="text-lg font-medium text-gray-900">Existing Categories</h3>
          <div className="mt-2 grid grid-cols-1 gap-4">
            {categories.map((category) => (
              <div
                key={category.id}
                className="bg-gray-50 p-4 rounded-md"
              >
                <div className="flex items-start space-x-4">
                  {category.imageUrl && (
                    <img 
                      src={category.imageUrl} 
                      alt={category.name}
                      className="w-20 h-20 object-cover rounded-md"
                    />
                  )}
                  <div>
                    <h4 className="text-sm font-medium text-gray-900">{category.name}</h4>
                    {category.shortDescription && (
                      <p className="mt-1 text-sm text-gray-500">{category.shortDescription}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;

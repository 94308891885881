import React from 'react'

function SectionSideBySide({ img, heading, text }) {
  return (
    <section className="w-full py-20 bg-white">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row items-center gap-16">
          {/* Image Section */}
          <div className="w-full md:w-1/2">
            <div className="border-2 border-primary p-2">
              <img 
                src={img} 
                alt={heading}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          
          {/* Content Section */}
          <div className="w-full md:w-1/2 text-center">
            <h2 className="text-4xl font-secondary mb-8">
              {heading}
            </h2>
            <p className="font-primary leading-relaxed max-w-xl mx-auto">
              {text}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionSideBySide

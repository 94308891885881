import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

const BookingContext = createContext();

export function BookingProvider({ children }) {
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const [categoriesSnapshot, servicesSnapshot, locationsSnapshot] = await Promise.all([
          getDocs(collection(db, 'categories')),
          getDocs(collection(db, 'services')),
          getDocs(collection(db, 'locations'))
        ]);

        const categoriesData = categoriesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(categoriesData);

        const servicesData = servicesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setServices(servicesData);

        const locationsData = locationsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setLocations(locationsData);

        // Auto-select location if there's only one
        if (locationsData.length === 1) {
          setSelectedLocation(locationsData[0]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load services. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleService = (service) => {
    setSelectedServices(prev => {
      const isSelected = prev.find(s => s.id === service.id);
      if (isSelected) {
        return prev.filter(s => s.id !== service.id);
      } else {
        return [...prev, service];
      }
    });
  };

  const getTotalDuration = () => {
    const totalMinutes = selectedServices.reduce((total, service) => {
      const serviceHours = service.bookingLength?.hours || 0;
      const serviceMinutes = service.bookingLength?.minutes || 0;
      return total + (serviceHours * 60) + serviceMinutes;
    }, 0);

    return {
      hours: Math.floor(totalMinutes / 60),
      minutes: totalMinutes % 60
    };
  };

  const getTotalPrice = () => {
    return selectedServices.reduce((total, service) => total + (service.price || 0), 0);
  };

  const getOrderedServices = () => {
    if (selectedCategory) {
      return services.filter(service => service.category.toLowerCase() === selectedCategory);
    }
    
    // When no category is selected, order services by category order
    return categories.reduce((orderedServices, category) => {
      const categoryServices = services.filter(
        service => service.category.toLowerCase() === category.name.toLowerCase()
      );
      return [...orderedServices, ...categoryServices];
    }, []);
  };

  const filteredServices = getOrderedServices();

  return (
    <BookingContext.Provider
      value={{
        categories,
        services: filteredServices,
        locations,
        selectedServices,
        selectedCategory,
        selectedLocation,
        loading,
        error,
        setSelectedCategory,
        setSelectedLocation,
        toggleService,
        getTotalDuration,
        getTotalPrice,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
}

export function useBooking() {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error('useBooking must be used within a BookingProvider');
  }
  return context;
}

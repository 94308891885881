import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function BookingConfirmation() {
  const location = useLocation();
  const navigate = useNavigate();
  const bookingDetails = location.state;

  if (!bookingDetails) {
    return (
      <div className="min-h-screen bg-white pt-24">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center">
            <h1 className="text-2xl font-semibold text-gray-900 mb-4">
              No booking details found
            </h1>
            <p className="text-gray-600 mb-8">
              Please try making a new booking
            </p>
            <button
              onClick={() => navigate('/booking')}
              className="bg-primary text-white px-6 py-2 rounded-lg hover:bg-primary/90 transition-colors"
            >
              Make a Booking
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white pt-24">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-center mb-8">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-100 mb-4">
              <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <h1 className="text-3xl font-semibold text-gray-900 mb-2">
              Booking Confirmed!
            </h1>
            <p className="text-gray-600">
              Your appointment has been successfully booked
            </p>
          </div>

          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium mb-2">Booking Reference</h3>
              <p className="text-gray-600">{bookingDetails.bookingId}</p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">Services Booked</h3>
              {bookingDetails.services.map(service => (
                <div key={service.id} className="flex justify-between py-2">
                  <span className="text-gray-600">{service.title}</span>
                  <span className="text-gray-600">£{service.price}</span>
                </div>
              ))}
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">Appointment Time</h3>
              <p className="text-gray-600">
                {new Date(bookingDetails.dateTime).toLocaleString()}
              </p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">Total Duration</h3>
              <p className="text-gray-600">
                {bookingDetails.totalDuration.hours}h {bookingDetails.totalDuration.minutes}m
              </p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">Total Price</h3>
              <p className="text-xl font-bold text-primary">
                £{bookingDetails.totalPrice}
              </p>
            </div>

            <div className="pt-6 border-t">
              <p className="text-gray-600 text-sm mb-4">
                A confirmation email has been sent to {bookingDetails.userEmail}
              </p>
              <button
                onClick={() => navigate('/')}
                className="w-full bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary/90 transition-colors"
              >
                Return to Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingConfirmation;

import React from 'react';

function ConfirmationStep({ 
  selectedServices, 
  selectedDateTime,
  selectedLocation,
  totalPrice, 
  isSubmitting, 
  error,
  onBack,
  onConfirm 
}) {
  return (
    <div className="max-w-3xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <button
          onClick={onBack}
          className="text-gray-600 hover:text-gray-800 flex items-center"
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
          Back to Date & Time
        </button>
      </div>
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-semibold mb-6">Confirm Your Booking</h2>
        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
            {error}
          </div>
        )}
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium mb-2">Location</h3>
            <p>{selectedLocation?.name}</p>
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Selected Services</h3>
            {selectedServices.map(service => (
              <div key={service.id} className="flex justify-between py-2">
                <span>{service.title}</span>
                <span>£{service.price}</span>
              </div>
            ))}
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Appointment Time</h3>
            <p>{selectedDateTime?.toLocaleString()}</p>
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Total</h3>
            <p className="text-xl font-bold">£{totalPrice}</p>
          </div>
          <button
            onClick={onConfirm}
            disabled={isSubmitting}
            className={`w-full bg-black text-white px-6 py-3 rounded-lg transition-colors ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-black/90'
            }`}
          >
            {isSubmitting ? 'Confirming...' : 'Confirm Booking'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationStep;

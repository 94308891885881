import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../context/AuthContext';
import { useBooking } from '../context/BookingContext';
import DateTimeSelection from '../components/Booking/DateTimeSelection';
import CategoryFilter from '../components/Booking/CategoryFilter';
import ServiceList from '../components/Booking/ServiceList';
import BookingSummary from '../components/Booking/BookingSummary';
import ConfirmationStep from '../components/Booking/ConfirmationStep';
import PaymentStep from '../components/Booking/PaymentStep';

function Booking() {
  const [step, setStep] = useState(1);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  
  const { user, isTrustedUser } = useAuth();
  const navigate = useNavigate();
  const {
    categories,
    services,
    selectedServices,
    selectedCategory,
    selectedLocation,
    loading,
    error: fetchError,
    setSelectedCategory,
    toggleService,
    getTotalDuration,
    getTotalPrice,
  } = useBooking();

  const handleContinue = () => {
    if (selectedServices.length > 0) {
      setStep(2);
      window.scrollTo(0, 0);
    }
  };

  const handleTimeSlotSelect = (dateTime) => {
    setSelectedDateTime(dateTime);
    if (!user) {
      navigate('/signin', { 
        state: { 
          returnTo: '/booking',
          bookingData: {
            services: selectedServices,
            dateTime: dateTime,
            locationId: selectedLocation?.id
          }
        } 
      });
    } else {
      // If user is trusted, go straight to confirmation
      // Otherwise, go to payment step
      setStep(isTrustedUser ? 3 : 4);
    }
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
    window.scrollTo(0, 0);
  };

  const handlePaymentComplete = (paymentDetails) => {
    setPaymentIntent(paymentDetails);
    setStep(3); // Move to confirmation after payment
  };

  const handleConfirmBooking = async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      const bookingData = {
        userId: user.uid,
        userEmail: user.email,
        services: selectedServices.map(service => ({
          id: service.id,
          title: service.title,
          price: service.price,
          bookingLength: service.bookingLength
        })),
        dateTime: selectedDateTime,
        locationId: selectedLocation.id,
        locationName: selectedLocation.name,
        totalPrice: getTotalPrice(),
        totalDuration: getTotalDuration(),
        status: 'pending',
        createdAt: new Date(),
        isTrustedUser,
        payment: paymentIntent ? {
          id: paymentIntent.id,
          amount: Math.floor(getTotalPrice() * 50), // 50% deposit
          status: 'completed'
        } : null
      };

      const docRef = await addDoc(collection(db, 'bookings'), bookingData);
      
      navigate('/booking-confirmation', { 
        state: { 
          bookingId: docRef.id,
          ...bookingData
        }
      });
    } catch (error) {
      console.error('Error creating booking:', error);
      setError('Failed to create booking. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white pt-24">
        <div className="text-xl text-gray-600">Loading services...</div>
      </div>
    );
  }

  if (fetchError) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white pt-24">
        <div className="text-xl text-red-600">{fetchError}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {step === 1 && (
          <>
            <h1 className="text-4xl font-bold text-gray-900 mb-8">
              Select services
            </h1>

            <CategoryFilter
              categories={categories}
              selectedCategory={selectedCategory}
              onCategorySelect={setSelectedCategory}
            />

            <ServiceList
              services={services}
              selectedServices={selectedServices}
              onServiceToggle={toggleService}
            />

            {selectedServices.length > 0 && (
              <BookingSummary
                selectedServices={selectedServices}
                totalDuration={getTotalDuration()}
                totalPrice={getTotalPrice()}
                onContinue={handleContinue}
              />
            )}
          </>
        )}

        {step === 2 && (
          <div className="max-w-3xl mx-auto">
            <div className="flex justify-between items-center mb-8">
              <button
                onClick={handleBack}
                className="text-gray-600 hover:text-gray-800 flex items-center"
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                Back to Services
              </button>
            </div>
            <DateTimeSelection
              selectedServices={selectedServices}
              onTimeSlotSelect={handleTimeSlotSelect}
            />
          </div>
        )}

        {step === 4 && user && !isTrustedUser && (
          <PaymentStep
            totalPrice={getTotalPrice()}
            isSubmitting={isSubmitting}
            error={error}
            onBack={handleBack}
            onPaymentComplete={handlePaymentComplete}
          />
        )}

        {step === 3 && user && (
          <ConfirmationStep
            selectedServices={selectedServices}
            selectedDateTime={selectedDateTime}
            selectedLocation={selectedLocation}
            totalPrice={getTotalPrice()}
            isSubmitting={isSubmitting}
            error={error}
            onBack={handleBack}
            onConfirm={handleConfirmBooking}
          />
        )}
      </div>
    </div>
  );
}

export default Booking;

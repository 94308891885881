import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AuthProvider } from './context/AuthContext';
import { BookingProvider } from './context/BookingContext';
import NavigationBar from './components/Navigation/NavigationBar';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Home from './pages/Home';
import Services from './pages/Services';
import ServiceCategory from './pages/ServiceCategory';
import Booking from './pages/Booking';
import BookingConfirmation from './pages/BookingConfirmation';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Admin from './pages/Admin';
import Contact from './pages/Contact';
import './App.css';

// TODO: Replace with your actual publishable key
const stripePromise = loadStripe('pk_test_your_publishable_key');

function App() {
  return (
    <AuthProvider>
      <BookingProvider>
        <Elements stripe={stripePromise}>
          <div className="flex flex-col min-h-screen">
            <NavigationBar />
            <main className="flex-grow">
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/:category" element={<ServiceCategory />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/booking-confirmation" element={<BookingConfirmation />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </Elements>
      </BookingProvider>
    </AuthProvider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';

const UserDetails = ({ userId, onClose }) => {
  const [appointments, setAppointments] = useState([]);
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch user data
        const userDoc = await getDoc(doc(db, 'users', userId));
        setUser(userDoc.data());
        setNotes(userDoc.data()?.adminNotes || '');

        // Fetch appointments
        const appointmentsQuery = query(
          collection(db, 'bookings'),
          where('userId', '==', userId)
        );
        const appointmentsSnapshot = await getDocs(appointmentsQuery);
        const appointmentsList = appointmentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date.toDate()
        }));
        
        // Sort appointments by date, most recent first
        setAppointments(appointmentsList.sort((a, b) => b.date - a.date));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const saveNotes = async () => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        adminNotes: notes
      });
    } catch (error) {
      console.error('Error saving notes:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{user?.email}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-medium mb-2">Admin Notes</h3>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-full p-2 border rounded-md"
            rows="4"
          />
          <button
            onClick={saveNotes}
            className="mt-2 bg-primary text-white px-4 py-2 rounded-md hover:bg-opacity-90"
          >
            Save Notes
          </button>
        </div>

        <div>
          <h3 className="text-lg font-medium mb-2">Previous Appointments</h3>
          {appointments.length === 0 ? (
            <p className="text-gray-500">No previous appointments</p>
          ) : (
            <div className="space-y-4">
              {appointments.map((appointment) => (
                <div
                  key={appointment.id}
                  className="border rounded-md p-4"
                >
                  <div className="flex justify-between">
                    <div>
                      <p className="font-medium">{appointment.serviceName}</p>
                      <p className="text-sm text-gray-500">
                        {appointment.date.toLocaleDateString()} at {appointment.date.toLocaleTimeString()}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className={`text-sm ${
                        appointment.status === 'completed' ? 'text-green-600' :
                        appointment.status === 'cancelled' ? 'text-red-600' :
                        'text-blue-600'
                      }`}>
                        {appointment.status?.charAt(0).toUpperCase() + appointment.status?.slice(1)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;

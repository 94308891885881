import { db } from '../config/firebase';
import { doc, setDoc } from 'firebase/firestore';

export const setupAdminUser = async (uid) => {
  try {
    await setDoc(doc(db, 'users', uid), {
      admin: true,
      email: 'sam@thegeekfloor.com',
      createdAt: new Date(),
      updatedAt: new Date()
    });
    console.log('Admin user document created successfully');
  } catch (error) {
    console.error('Error creating admin user document:', error);
  }
};

import React from 'react';

function BookingSummary({ selectedServices, totalDuration, totalPrice, onContinue }) {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-900 text-sm">
              {selectedServices.length} service{selectedServices.length !== 1 ? 's' : ''} selected
            </p>
            <p className="text-sm text-gray-500">
              {totalDuration.hours}h {totalDuration.minutes}m
            </p>
          </div>
          <div className="flex items-center space-x-6">
            <p className="text-lg">
              £{totalPrice}
            </p>
            <button
              onClick={onContinue}
              className="bg-black text-white px-8 py-2.5 rounded-lg hover:bg-black/90 transition-colors"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingSummary;

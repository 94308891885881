import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import AddService from '../components/Admin/AddService';
import AddCategory from '../components/Admin/AddCategory';
import BookingSettings from '../components/Admin/BookingSettings';
import ViewBookings from '../components/Admin/ViewBookings';
import ManageUsers from '../components/Admin/ManageUsers';
import ManageLocations from '../components/Admin/ManageLocations';

const Admin = () => {
  const { user, isAdmin } = useAuth();
  const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard');

  if (!user || !isAdmin) {
    return <Navigate to="/" replace />;
  }

  const menuItems = [
    { id: 'dashboard', label: 'Dashboard' },
    { id: 'view-bookings', label: 'View Bookings' },
    { id: 'manage-users', label: 'Manage Users' },
    { id: 'manage-categories', label: 'Manage Categories' },
    { id: 'add-service', label: 'Add Service' },
    { id: 'manage-locations', label: 'Manage Locations' },
    { id: 'booking-settings', label: 'Booking Settings' },
  ];

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'manage-categories':
        return <AddCategory />;
      case 'add-service':
        return <AddService />;
      case 'booking-settings':
        return <BookingSettings />;
      case 'view-bookings':
        return <ViewBookings />;
      case 'manage-users':
        return <ManageUsers />;
      case 'manage-locations':
        return <ManageLocations />;
      case 'dashboard':
      default:
        return (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Dashboard Overview
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Welcome to your admin dashboard
              </p>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Logged in as
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user.email}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex">
        {/* Sidebar */}
        <div className="w-64 bg-white shadow-md min-h-screen pt-32">
          <div className="px-4 py-5">
            <h2 className="text-lg font-semibold text-gray-900">Admin Menu</h2>
          </div>
          <nav className="mt-5">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setSelectedMenuItem(item.id)}
                className={`w-full text-left px-4 py-2 text-sm font-medium ${
                  selectedMenuItem === item.id
                    ? 'bg-primary text-white'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                {item.label}
              </button>
            ))}
          </nav>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Admin Dashboard</h1>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Admin;

import React from 'react';

function ServiceList({ services, selectedServices, onServiceToggle }) {
  return (
    <div className="space-y-4">
      {services.map(service => {
        const isSelected = selectedServices.find(s => s.id === service.id);
        return (
          <div
            key={service.id}
            onClick={() => onServiceToggle(service)}
            className={`cursor-pointer transition-colors px-6 py-4 -mx-6 ${
              isSelected ? 'bg-primary text-white rounded-xl' : 'hover:bg-gray-50'
            }`}
          >
            <div className="flex justify-between items-center">
              <h3 className="font-['Bodoni_Classico'] text-xl">
                {service.title}
              </h3>
              <div className="flex items-center">
                <span className={`text-sm mr-28 w-20 text-right ${
                  isSelected ? 'text-white/80' : 'text-gray-500'
                }`}>
                  {service.bookingLength?.hours}h {service.bookingLength?.minutes}m
                </span>
                <div className="w-16 text-right">
                  <span className="text-lg">
                    £{service.price}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ServiceList;

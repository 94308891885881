import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../config/firebase';

const WeeklyView = ({ currentWeek, onPreviousWeek, onNextWeek, onDateClick, onBookingClick, bookings }) => {
  const weekDays = Array.from({ length: 7 }, (_, i) => currentWeek.add(i, 'day'));
  const [businessHours, setBusinessHours] = useState({
    monday: { start: '09:00', end: '17:00', isOpen: true },
    tuesday: { start: '09:00', end: '17:00', isOpen: true },
    wednesday: { start: '09:00', end: '17:00', isOpen: true },
    thursday: { start: '09:00', end: '17:00', isOpen: true },
    friday: { start: '09:00', end: '17:00', isOpen: true },
    saturday: { start: '09:00', end: '17:00', isOpen: false },
    sunday: { start: '09:00', end: '17:00', isOpen: false },
  });

  useEffect(() => {
    const fetchBookingSettings = async () => {
      try {
        const settingsDoc = await getDocs(collection(db, 'settings'));
        const bookingSettings = settingsDoc.docs.find(doc => doc.id === 'booking');
        if (bookingSettings) {
          const data = bookingSettings.data();
          setBusinessHours(data.businessHours || businessHours);
        }
      } catch (error) {
        console.error('Error fetching booking settings:', error);
      }
    };

    fetchBookingSettings();
  }, []);

  // Find earliest start time and latest end time across all business days
  const getTimeRange = () => {
    let earliestStart = 24;
    let latestEnd = 0;

    Object.values(businessHours).forEach(hours => {
      if (hours.isOpen) {
        const [startHour] = hours.start.split(':').map(Number);
        const [endHour] = hours.end.split(':').map(Number);
        
        earliestStart = Math.min(earliestStart, startHour);
        latestEnd = Math.max(latestEnd, endHour);
      }
    });

    return Array.from(
      { length: latestEnd - earliestStart },
      (_, i) => earliestStart + i
    );
  };

  const timeSlots = getTimeRange();

  const handleBookingClick = (e, booking) => {
    e.stopPropagation(); // Prevent triggering the time slot click
    if (onBookingClick) {
      onBookingClick(booking);
    }
  };

  const getBookingColor = (status) => {
    switch (status) {
      case 'confirmed':
        return 'bg-green-100 border-green-200 text-green-800';
      case 'pending':
        return 'bg-yellow-100 border-yellow-200 text-yellow-800';
      case 'rejected':
        return 'bg-red-100 border-red-200 text-red-800';
      case 'cancelled':
        return 'bg-gray-100 border-gray-200 text-gray-800';
      default:
        return 'bg-gray-100 border-gray-200 text-gray-800';
    }
  };

  const calculateBookingPosition = (booking, slotStartHour) => {
    const bookingTime = dayjs(booking.dateTime);
    const bookingHour = bookingTime.hour();
    const bookingMinute = bookingTime.minute();
    
    // Calculate total duration in minutes
    const totalDurationMinutes = (booking.totalDuration.hours * 60) + booking.totalDuration.minutes;
    
    // Calculate position and height
    const hourHeight = 80; // height of each time slot in pixels (h-20 class = 5rem = 80px)
    const topOffset = ((bookingHour - slotStartHour) * hourHeight) + ((bookingMinute / 60) * hourHeight);
    const height = (totalDurationMinutes / 60) * hourHeight;
    
    return {
      top: `${topOffset}px`,
      height: `${height}px`
    };
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4">
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={onPreviousWeek}
          className="p-2 hover:bg-gray-100 rounded-full"
          aria-label="Previous week"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <div className="text-xl font-semibold text-center">
          {currentWeek.format('MMM D')} - {currentWeek.add(6, 'day').format('MMM D, YYYY')}
        </div>
        <button
          onClick={onNextWeek}
          className="p-2 hover:bg-gray-100 rounded-full"
          aria-label="Next week"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>

      {/* Legend */}
      <div className="flex gap-4 mb-4 text-sm">
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-green-100 border border-green-200 mr-1"></div>
          <span>Confirmed</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-yellow-100 border border-yellow-200 mr-1"></div>
          <span>Pending</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-red-100 border border-red-200 mr-1"></div>
          <span>Rejected</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-gray-100 border border-gray-200 mr-1"></div>
          <span>Cancelled</span>
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="grid grid-cols-8 min-w-[800px]">
          {/* Time column */}
          <div className="border-r">
            <div className="h-12"></div> {/* Empty cell for header alignment */}
            {timeSlots.map((hour) => (
              <div key={hour} className="h-20 border-t p-1 text-xs text-gray-500">
                {hour.toString().padStart(2, '0')}:00
              </div>
            ))}
          </div>
          {/* Days columns */}
          {weekDays.map((day, dayIndex) => (
            <div key={dayIndex} className="border-r">
              <div className="h-12 border-b p-2 text-center font-medium">
                <div>{day.format('ddd')}</div>
                <div className="text-sm text-gray-500">{day.format('MMM D')}</div>
              </div>
              <div className="relative">
                {timeSlots.map((hour) => (
                  <div
                    key={hour}
                    className="h-20 border-t p-1"
                    onClick={() => onDateClick(day.hour(hour).toDate())}
                  />
                ))}
                {bookings
                  .filter(booking => {
                    const bookingDay = dayjs(booking.dateTime);
                    return bookingDay.format('YYYY-MM-DD') === day.format('YYYY-MM-DD');
                  })
                  .map((booking) => {
                    const position = calculateBookingPosition(booking, timeSlots[0]);
                    const colorClasses = getBookingColor(booking.status);
                    return (
                      <div
                        key={booking.id}
                        className={`absolute left-0 right-0 mx-1 p-1 text-xs rounded overflow-hidden cursor-pointer hover:opacity-75 transition-opacity border ${colorClasses}`}
                        style={position}
                        onClick={(e) => handleBookingClick(e, booking)}
                      >
                        <div className="font-medium truncate">{booking.userEmail}</div>
                        <div className="truncate">{booking.services[0]?.title}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeeklyView;

import React from 'react'
import HomeHero from '../components/HomeHero/HomeHero'
import SectionSideBySide from '../components/SectionTemplate/SectionSideBySide'
import ServiceCategories from '../components/ServiceCategories/ServiceCategories'
import welcomeImage from '../assets/images/HomeHeroImages/SCHero1.jpeg'

function Home() {
  return (
    <>
      <HomeHero />
      <SectionSideBySide 
        img={welcomeImage}
        heading="Welcome to The Skin Chalet"
        text="Hello, I'm Sam, the proud owner of The Skin Chalet. With over 12 years of experience in various beauty settings, I am dedicated to helping women enhance their natural features through a range of treatments. While I am known as 'The Brow Lady' due to my passion for eyebrows, my expertise extends to other beauty services such as semi-permanent makeup, facials, and waxing. Located in Bookham, appointments at my clinic must be scheduled in advance. I'm excited to welcome you for a wonderful treatment experience."
      />
      <ServiceCategories />
    </>
  )
}

export default Home

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../config/firebase';
import { collection, query, getDocs } from 'firebase/firestore';
import { useBooking } from '../context/BookingContext';

const ServiceCategory = () => {
  const { category } = useParams();  // Changed from categoryName to category to match route param
  const [categoryData, setCategoryData] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { toggleService } = useBooking();

  useEffect(() => {
    const fetchCategoryAndServices = async () => {
      try {
        // Fetch all categories and find the matching one case-insensitively
        const categoriesQuery = query(collection(db, 'categories'));
        const categorySnapshot = await getDocs(categoriesQuery);
        const matchingCategory = categorySnapshot.docs.find(doc => 
          doc.data().name.toLowerCase() === decodeURIComponent(category).toLowerCase()
        );

        if (matchingCategory) {
          const categoryData = {
            id: matchingCategory.id,
            ...matchingCategory.data()
          };
          setCategoryData(categoryData);

          // Fetch services for this category
          const servicesQuery = query(collection(db, 'services'));
          const servicesSnapshot = await getDocs(servicesQuery);
          const servicesData = servicesSnapshot.docs
            .map(doc => ({
              id: doc.id,
              ...doc.data()
            }))
            .filter(service => 
              service.category.toLowerCase() === categoryData.name.toLowerCase()
            );
          setServices(servicesData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryAndServices();
  }, [category]);

  const handleBookNow = (service) => {
    toggleService(service);
    navigate('/booking');
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-white">Loading...</div>
      </div>
    );
  }

  if (!categoryData) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-white">Category not found</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Category Header with full-width image and overlay */}
      <div className="relative h-[60vh] w-full overflow-hidden">
        {categoryData.imageUrl && (
          <>
            <div 
              className="absolute inset-0 w-full h-full bg-cover bg-center"
              style={{
                backgroundImage: `url(${categoryData.imageUrl})`
              }}
            />
            <div className="absolute inset-0 bg-gradient-to-b from-primary/90 to-primary/70"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-center px-4">
                <h1 className="md:text-6xl text-xl font-secondary text-white tracking-[.5em]">
                  {categoryData.name.toUpperCase()}
                </h1>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Category Description */}
      {categoryData.description && (
        <div className="mx-auto p-32 text-center bg-primary">
          <p className="font-primary text-lg text-white tracking-wide leading-relaxed">
            {categoryData.description}
          </p>
        </div>
      )}

      {/* Services List */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {services.map((service) => (
          <div key={service.id} className="mb-24">
            <div className="flex justify-between items-baseline mb-6">
              <h3 className="text-2xl font-secondary max-w-[60%] text-primary">
                {service.title}
              </h3>
              <div className="flex items-baseline flex-1 ml-8">
                <div 
                  className="border-b border-dotted border-primary flex-grow mx-4"
                 
                ></div>
                <span className="text-2xl font-secondary text-primary">
                  £{service.price}
                </span>
              </div>
            </div>
            <p className="text-gray-600 font-primary mb-8 max-w-3xl text-lg leading-relaxed">
              {service.description}
            </p>
            <button 
              className="button-secondary"
              onClick={() => handleBookNow(service)}
             
            >
              Book Now
            </button>
          </div>
        ))}

        {services.length === 0 && (
          <div className="text-center text-gray-600 mt-8">
            No services available in this category yet.
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceCategory;
